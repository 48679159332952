import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';

const navItems = [
    {
        name: "About me",
        color: "#D6B161",
        href: "#about",
    },
    {
        name: "Work",
        color: "#3E497A",
        href: "#work",
    },
    // {
    //     name: "Skills & Expertise",
    //     color: "#D6B161",
    //     href: "#skills"
    // },
    // {
    //     name: "What's new",
    //     color: "#3E497A",
    //     href: "#blog"
    // },
    {
        name: "Get in touch",
        color: "#D6B161",
        href: "mailto:hello@annidavenport.com",
    },
];

const Header = () => {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

    useEffect(() => {
        if (theme === 'dark') {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
    }, [theme]);

    const switchTheme = () => {
        if (theme === 'dark') {
            localStorage.setItem('theme', 'light');
            setTheme('light');
        } else {
            localStorage.setItem('theme', 'dark');
            setTheme('dark');
        }
    }

    return (
        <header className="Header">
            <div className="grid grid-pad">
                <div className="Header__wrapper">
                    <a className="Logo" href="/" rel="noopener noreferrer">
                        Anni Davenport
                    </a>
                    <button className="Header__theme-switcher" onClick={switchTheme}>
                        {theme === 'dark' ? '🌞' : '🌙'}
                    </button>
                    <Navbar items={navItems} />
                </div>
            </div>
        </header>
    );
}


export default Header;