import React from "react";
import { useEffect } from "react";
import Modal from 'react-modal';

// Modal.setAppElement('.App');


export const GridTile = (props) => {
    const {
        title,
        tags,
        thumbnailUrl,
        paragraphContent,
        modalImgUrl
    } = props;

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.scrollY}px`;

        // When the modal is hidden, we want to remain at the top of the scroll position
        document.body.style.position = '';
        document.body.style.top = '';
    }

    function afterOpenModal() {
      // references are now sync'd and can be accessed.
    //   subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }

    useEffect(() => {
        Modal.setAppElement('.App');
    })

    return (
        <div className='grid-item' style={{backgroundImage: `url(${thumbnailUrl})`}} >
            <button className='grid-item__content' onClick={openModal} tabIndex={1}>
                <h3 className="grid-item__title">{title}</h3>
                <p>{tags.map((tag, index) => {
                    return <span className="pill" key={`tag-${index}`}>{tag}</span>
                })}</p>
            </button>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                contentLabel=""
                style={{
                    overlay: {
                       backgroundColor: 'rgba(0 , 0, 0, 0.75)'
                    },
                    content: {
                        position: 'absolute',
                        top: '80px',
                        left: '80px',
                        right: '80px',
                        bottom: '80px',
                        border: 'none',
                        background: 'var(--bg-banner)',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '20px'
                    }
                }}
            >
                <button onClick={closeModal} className="ReactModal__close"></button>
                <div className="grid grid-pad" style={{minWidth: 'none'}}>
                    <div className="col-5-12">
                        <img src={modalImgUrl} alt={title} />
                    </div>
                    <div className="col-7-12">
                        <h3>{title}</h3>
                        <p>{tags.map((tag, index) => {
                            return <span className="pill" key={`tag-${index}`}>{tag}</span>
                        })}</p>
                        {paragraphContent.map((para, index) => {
                            return  <p key={`para-${index}`}>{para}</p>
                        })}
                    </div>
                </div>
            </Modal>
        </div>
    );
}