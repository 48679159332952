// import logo from './logo.svg';
// import profilePic from './_assets/profile-pic.jpg';
import "./App.scss";
import Header from "./_components/3-organisms/Header/Header";
import Banner from "./_components/3-organisms/Banner/Banner";
// import Button from './_components/1-atoms/button/Button';
import { useEffect } from "react";
import aboutImage from "./_assets/about-illustration.svg";
import { GridTile } from "./_components/2-molecules/grid-tile/GridTile";
import portfolioContent from "./portfolio-content.json";
import Masonry from "masonry-layout";
// var scroller = new SmoothScroll({
//     target: document.querySelector("#scroll-container"),
//     scrollEase: 0.05,
// });

// import Modal from 'react-modal';

import IconEmail from "./_assets/icon-email.svg";
import IconLinkedIn from "./_assets/icon-linkedin.svg";

// eslint-disable-next-line no-unused-vars
const copyContent = async () => {
    try {
        await navigator.clipboard.writeText("hello@annidavenport.com");
        console.log("Content copied to clipboard");
    } catch (err) {
        console.error("Failed to copy: ", err);
    }
};



function App() {
    // const [test, setTest] = useState({});

    useEffect(() => {
        var elem = document.querySelector(".Masonry-grid");
        // eslint-disable-next-line
        var msnry = new Masonry(elem, {
            // options
            itemSelector: ".grid-item",
        });

        // Modal.setAppElement('.App');
    });

    return (
        <div className="App">
            <Header />

            <main>

                <Banner />

                <section id="about" className="mt-80 bg-alt">
                    <div className="grid grid-pad">
                        <div className="col-5-12">
                            <img
                                className="Banner__bg-imfdfdg"
                                src={aboutImage}
                                alt="Anni Davenport, UX Engineer"
                            />
                        </div>

                        <div className="col-7-12">
                            <div className="line-animations">
                                <div className="line-bg"></div>
                            </div>
                            <h2>A bit about me</h2>
                            <p>
                                With a blend of technical skill and design
                                acumen, I am a UX Engineer specializing in the
                                development and implementation of design
                                systems. I have spent the last 10 years
                                developing my skills in JavaScript, HTML, CSS,
                                as well as mastering design tools such as Figma,
                                Sketch, and Adobe XD.
                            </p>
                            <p>
                                My focus and expertise lies in building and
                                managing scalable, consistent design system
                                component libraries that foster collaboration
                                between designers and developers while
                                maintaining the highest standards of user
                                experience. My ability to translate high-level
                                design principles into functional and visually
                                appealing user interfaces is complemented by a
                                passion for user-centric design, ensuring that I
                                always put users' needs at the forefront of my
                                decisions.
                            </p>
                            <p>
                                Drawing on the latest industry trends, my work
                                balances innovative thinking with a methodical
                                approach, creating effective, intuitive, and
                                consistent experiences across all platforms. A
                                keen eye for detail and an inherent
                                understanding of the symbiotic relationship
                                between design and technology form the
                                foundation of my UX Engineering journey,
                                resulting in a unique blend of creative and
                                technical competencies.
                            </p>
                            <a
                                href="./Resume-2023.pdf"
                                className="btn btn--primary"
                                target="_blank"
                            >
                                Download my CV
                            </a>
                        </div>
                    </div>
                </section>

                <section id="work" className="mt-80">
                    <div className="grid grid-pad">
                        <div className="col-12">
                            <div className="line-animations">
                                <div className="line-bg"></div>
                            </div>
                            <h2>A few pieces of work</h2>

                            <p>
                                Here is a snapshot of work from over the years.
                                Each project represents a unique challenge and
                                solution, demonstrating my approach to
                                problem-solving, technical skills, and design
                                thinking.
                            </p>

                            <div className="Masonry-grid">
                                {portfolioContent.portfolio.map(
                                    (item, index) => {
                                        console.log(item);

                                        return (
                                            <GridTile
                                                key={item.title}
                                                title={item.title}
                                                tags={item.tags}
                                                thumbnailUrl={item.thumbnailUrl}
                                                paragraphContent={
                                                    item.paragraphContent
                                                }
                                                modalImgUrl={item.thumbnailUrl}
                                            />
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section id="skills" className="mt-80">
                    <div className="grid grid-pad">
                        <div className="col-12">
                            <div className="line-animations">
                                <div className="line-bg"></div>
                            </div>
                            <h2>Skills & expertise</h2>
                        </div>
                    </div>
                </section>

                <section id="blog" className="mt-80">
                    <div className="grid grid-pad">
                        <div className="col-12">
                            <div className="line-animations">
                                <div className="line-bg"></div>
                            </div>
                            <h2>What's New</h2>
                        </div>
                    </div>
                </section> */}

                <section
                    id="contact"
                    className="mt-80 pb-80"
                    style={{ backgroundColor: "var(--bg)" }}
                >
                    <div className="grid grid-pad">
                        <div className="col">
                            <div className="line-animations">
                                <div className="line-bg"></div>
                            </div>
                            <h2>Say hello!</h2>
                            <p>Get in touch with me via LinkedIn or email</p>

                            <div className="grid">
                                <div className="col-1">
                                    <a
                                        className="contactIcon icon-button"
                                        href="https://www.linkedin.com/in/annidavenport/"
                                    >
                                        <img
                                            className=""
                                            src={IconLinkedIn}
                                            alt="Connect with Anni on LinkedIn"
                                        />
                                    </a>
                                </div>
                                <div className="col-1">
                                    <a
                                        href="mailto:hello@annidavenport.com"
                                        className="contactIcon icon-button"
                                    >
                                        <img
                                            className=""
                                            src={IconEmail}
                                            alt="Connect with Anni via email"
                                        />
                                    </a>

                                    {/* <button onClick={copyContent} className="contactIcon icon-button">
                                        <img className="" src={IconEmail} alt="Connect with Anni via email"/>
                                    </button> */}
                                </div>
                                <div className="col-6-12"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default App;
