import { useRef, useState, useEffect, createRef } from 'react';
import { gsap } from "gsap";
// import Button from '../../1-atoms/button/Button';
// import smoothScroll from 'smoothscroll';

const Navbar = ({ items }) => {
    const $root = useRef()
    const $indicator = useRef()
    const $items = useRef(items.map(createRef))
    const [active, setActive] = useState(0)

    const animate = () => {
        const menuOffset = $root.current.getBoundingClientRect();

        const activeItem = $items.current[active].current;
        const { width, top, left } = activeItem.getBoundingClientRect();

        const indicatorSettings = {
            x: left - menuOffset.x -200,
            y: top - menuOffset.y - 170,
            width: width,
            height: '3px',
            position: 'absolute',
            backgroundColor: items[active].color,
            ease: 'elastic.out(.7, .7)',
            duration: .8
        }

        gsap.to($indicator.current, {
            ...indicatorSettings,
        });
    }


    // gsap.set("#overlay", { x: "300px" });
    // var isOpen = false;
    // const overlayAnim = gsap.to("#overlay", {
    //     duration: 1,
    //     ease: "power4.out",
    //     x: '0',
    //     autoAlpha: 1,
    //     paused: true
    // });

    // const toggleMobileNav = () => {
    //     console.log('expandNav');
    //     isOpen = !isOpen; //toggle

    //     if (isOpen) {
    //         overlayAnim.play();
    //     } else {
    //         overlayAnim.reverse();
    //     }

    // }
    const handleScroll = (href) => {
        console.log(this);
        // // console.log(href);
        // // event.preventDefault();
        console.log('doop');
        console.log(href);

        // smoothScroll();

    }

    useEffect(() => {
        animate()
        window.addEventListener('resize', animate)


        return (() => {
            window.removeEventListener('resize', animate)
        })
        // eslint-disable-next-line
    }, [active])

    return (
        <div className="Navbar">
            {/* <nav
                ref={$root}
                className="Navbar__links"
            >
                {items.map((item, index) => (
                    <a
                        key={item.name}
                        ref={$items.current[index]}
                        className={`Navbar__item ${active === index ? 'active' : ''}`}
                        onMouseEnter={() => {
                            setActive(index)
                        }}
                        href={item.href}
                    >
                        {item.name}
                    </a>
                ))}

                <div
                    ref={$indicator}
                    className="Navbar__indicator"
                />

            </nav> */}



            <div className="mobile-nav-container icon-button">
                <input id="toggle" type="checkbox" />
                <label className="toggle-container " htmlFor="toggle">
                    <span className="button button-toggle "></span>
                </label>

                {/* <!-- The Nav Menu --> */}
                <nav className="mobile-nav"  ref={$root}>
                    {items.map((item, index) => (
                        <a
                            key={item.name}
                            ref={$items.current[index]}
                            className={`mobile-nav-item ${active === index ? 'active' : ''}`}
                            onMouseEnter={() => {
                                setActive(index)
                            }}
                            href={item.href}
                            onClick={() => {handleScroll(item.href)}}
                        >
                            {item.name}
                        </a>
                    ))}
                    <div
                        ref={$indicator}
                        className="Navbar__indicator"
                    />
                </nav>
            </div>


            {/* <Button
                onClick={toggleMobileNav}
                label={[
                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1H12" stroke="#D6B161" stroke-linecap="round" />
                        <path d="M1 5H12" stroke="#D6B161" stroke-linecap="round" />
                        <path d="M1 9H12" stroke="#D6B161" stroke-linecap="round" />
                    </svg>, <p>&nbsp;&nbsp;Menu</p>]}
                variant="primary"
                size="tiny"
            />

            <div id="overlay" className="">pineapple</div> */}
            {/* <div className="bar-hamburger">
                <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1H12" stroke="#D6B161" stroke-linecap="round"/>
                    <path d="M1 5H12" stroke="#D6B161" stroke-linecap="round"/>
                    <path d="M1 9H12" stroke="#D6B161" stroke-linecap="round"/>
                </svg>

                <p>Menu</p>
            </div> */}
        </div>
    )
}

export default Navbar;