// import { react } from "react";

const Blobby = (props) => {
    const {
        width = '250px',
        height = '250px',
        color = 'var(--gold)',
        top = 'unset',
        left = 'unset',
        bottom = 'unset',
        right = 'unset',

    } = props;

    return (
        <div
            className="Blobby"
            style={{
                width: width,
                height: height,
                backgroundColor: color,
                position: 'absolute',
                top: top,
                left: left,
                bottom: bottom,
                right: right,
            }}
        ></div>
    );
}

export default Blobby;