// import { ImgComparisonSliderz } from '../../../_vendor/image-comparison-slider/ImageComparisonSlider.ts';
import { useState, useEffect } from 'react';
import bannerImage from '../../../_assets/image.png';
import Blobby from '../../1-atoms/Blobby/Blobby.js';


const Banner = () => {
    // eslint-disable-next-line
    const [mousePos, setMousePos] = useState({});
    // const [bannerWidth, setBannerWidth] = useState({});
    // eslint-disable-next-line
    const [doop, setDoop] = useState({});

    useEffect(() => {
        setDoop('pineapple');
        const handleMouseMove = (event) => {
            setMousePos({ x: event.clientX, y: event.clientY });
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener(
                'mousemove',
                handleMouseMove
            );
        };
    }, []);


    return (
        // <div className='Banner' style={{ backgroundImage: `url(${bannerImage})` }}>
        <div className='Banner' style={{}} >
            {/* <p>({mousePos.x})</p> */}


            <div className="grid Banner__background-elements">
                <Blobby top="155px" left="357px" />
                <Blobby top="95px" left="57px" width="100px" height="100px" />

                <img className="Banner__bg-img" src={bannerImage} alt="Anni Davenport" />
            </div>

            <div className="grid Banner__content-box">
                <div className="col-6-12">
                <div className="line-animations">
                    <div className="line-bg line-bg--inverted"></div>
                </div>
                <h1>ANNI DAVENPORT</h1>
                <p className="lead">UX Engineer - the magic of design meets the alchemy of code</p>
                </div>
            </div>

        </div>
    );
}
// TODO
//  - reset to center
// - transition time
// - CSS filters
// New photo

export default Banner;

